import * as React from "react";
import {ucFirstWords,buildProductLink,generateStructuredData} from "../general/utils";
import AddToCartButton from "./AddToCartButton";
import {DEFAULT_IMAGE} from "../general/constants";
import {SHOP_LOGOS} from '../general/constants';

const Product = ({productData,handleAddToList,qtyInList,handleRemoveFromList,handleShowProductDetails})=>{
  const title = `${productData.supermarket_name.toUpperCase()} > ${ucFirstWords(productData.category_name)} > ${productData.product_name}`;

  const productTitle = productData.product_name;

  const productLink = buildProductLink(productData);

  return (
      <a
        title={title}
        className="product product-link col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3"
        href={productLink}
        onClick={e=>{
          e.preventDefault();
          handleShowProductDetails(productData);
        }} >

          <script type="application/ld+json" dangerouslySetInnerHTML={{__html: generateStructuredData(productData)}}></script>          

          <div className="product-image">
            <img src={`${productData.product_image}`} alt={title} onError={e=>{if(!e.target.src.endsWith(DEFAULT_IMAGE)){e.target.src=DEFAULT_IMAGE;}}} />
          </div>

          <div className="shop-logo">
            {SHOP_LOGOS.get(productData.supermarket_name)}
          </div>

          {productData.hasOwnProperty("product_promotion")
          && productData.product_promotion
          && productData.product_promotion?.length > 0
          &&
          <div className="latest-deal">
            <div title={`${productData.product_promotion}`} className="percentage-off">{productData.product_promotion}</div>
          </div>
          }

          <div className="product-details">
            <h5 className="product-title">{productTitle}</h5>
            <h5 className="product-price">{`£${productData.product_price.toFixed(2)}`}</h5>

            <AddToCartButton handleAddToList={handleAddToList} qtyInList={qtyInList} handleRemoveFromList={handleRemoveFromList} productData={productData}/>

          </div>
      </a>
  );
};

export default Product;
