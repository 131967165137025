import * as React from "react";
import {ucFirstWords} from "../general/utils";

const CheckboxPanel = ({options, handleChange, handleReset})=>{
  const allInputs = options.map(op=>{
    return (
      <div key={op.name} className="row checkbox-row">
        <div className="col">
          <div className={`checkbox-container ${op.disabled ? "disabled" : ""}`}>
            <label htmlFor={op.name}>
              {`${ucFirstWords(op.name)} (${op.count.toLocaleString()})`}
            </label>
            <input
              className="my-checkbox-input"
              type="checkbox"
              id={op.name}
              name={op.name}
              value={op.name}
              checked={op.checked ? "checked" : ""}
              onChange={e => handleChange({name: e.target.name, checked: e.target.checked})}
              disabled={op.disabled}
              />
          </div>
        </div>
      </div>
    );
  });

  return (
      <div className="checkbox-panel" onClick={e=>e.stopPropagation()} >

        <div key="reset-btn" className="row mb-2 reset-row">
          <div className="col">

          {options.some(op=>op.checked) &&
            <button type="button" className="reset-btn btn btn-danger" onClick={e=>handleReset()}>Reset Categories</button>
          }

          </div>
        </div>

        {allInputs}
      </div>
  );
};

export default CheckboxPanel;
