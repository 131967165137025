import {default as React, useReducer,} from "react";
import { io } from "socket.io-client";
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import * as Sentry from "@sentry/react";

import FilterableProductList from "./FilterableProductList";
import Header from "./Header";
import Footer from "./Footer";
import ErrorBoundary from "./ErrorBoundary";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import {cloneDeep,DEFAULT_STATE,initState,reducer} from '../state';

import './App.css';

const socketIOClient = io();

const App = () =>{
  const hasPreLoadedState = window.hasOwnProperty("__PRELOADED_STATE__") && Object.keys(window.__PRELOADED_STATE__)?.length > 0;
  const preloadedState = hasPreLoadedState ? window.__PRELOADED_STATE__ : cloneDeep(DEFAULT_STATE);

  if(hasPreLoadedState){
    delete window.__PRELOADED_STATE__;
  }

  const [state, dispatch] = useReducer(reducer, preloadedState ?? DEFAULT_STATE, initState);

  const fpl = (<FilterableProductList state={state} dispatch={dispatch} socketIO={socketIOClient} />);

  return (
    <Sentry.ErrorBoundary 
      fallback={ ({ error }) => (
        <ErrorBoundary errorMsg={error.toString()} hasError={true} />
      )}>
      <Router>

        <Header/>

        <Routes>

          <Route path={"/shop/*"} element={fpl} />
          <Route path={"/product/*"} element={fpl} />
          <Route path={"/brand/*"} element={fpl} />
          <Route path='/privacy-policy/*' element={<PrivacyPolicy/>} />

          <Route path="/" element={fpl} />
        </Routes>

        <Footer dispatch={dispatch} />

      </Router>
    </Sentry.ErrorBoundary>
  );
};

export default App;
