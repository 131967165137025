const IO_EVENT_CONNECTION = "connection"
const IO_EVENT_DO_SEARCH = "do-search";
const IO_EVENT_ALT_PRODUCT_SEARCH = "alt-product-search";
const IO_EVENT_ALT_PRODUCT_SEARCH_RESULTS = "alt-product-search-results";
const IO_EVENT_SEARCH_RESULTS = "search-results";
const IO_EVENT_SEARCH_ERROR = "search-error";
const IO_EVENT_FETCH_PRODUCTS = "fetch-products";
const IO_EVENT_FETCH_PRODUCTS_RESULTS = "fetch-products-results";
const IO_EVENT_FETCH_SINGLE_RESULT = "fetch-single-result";
const IO_EVENT_FETCH_SINGLE = "fetch-single";
const IO_EVENT_TERM_SEARCH = "term-search";
const IO_EVENT_TERM_SEARCH_RESULTS = "term-search-results";
const IO_EVENT_SWITCH_PRODUCT = "switch-product";
const IO_EVENT_SWITCH_PRODUCT_RESULT = "switch-product-result";
const IO_EVENT_DO_SEARCH_NO_FACET = "do-search-no-facet";
const IO_EVENT_DO_SEARCH_NO_FACET_RESULTS = "search-results-no-facet";

export {
  IO_EVENT_DO_SEARCH_NO_FACET,
  IO_EVENT_DO_SEARCH_NO_FACET_RESULTS,
  IO_EVENT_SWITCH_PRODUCT,
  IO_EVENT_SWITCH_PRODUCT_RESULT,
  IO_EVENT_TERM_SEARCH,
  IO_EVENT_TERM_SEARCH_RESULTS,
  IO_EVENT_CONNECTION,
  IO_EVENT_FETCH_SINGLE,
  IO_EVENT_FETCH_SINGLE_RESULT,
  IO_EVENT_ALT_PRODUCT_SEARCH,
  IO_EVENT_ALT_PRODUCT_SEARCH_RESULTS,
  IO_EVENT_DO_SEARCH,
  IO_EVENT_SEARCH_ERROR,
  IO_EVENT_SEARCH_RESULTS,
  IO_EVENT_FETCH_PRODUCTS,
  IO_EVENT_FETCH_PRODUCTS_RESULTS
};
