import * as React from "react";
import ShowMoreButton from "./ShowMoreButton";
import {MAX_SEARCH_RESULTS} from "../general/constants";

const ActionBar = ({hasResults, maxResults, handleMoreResults})=>{
  return (
      <div className="action-bar row mt-5 mb-5">
        <div className="col col-12">
        {hasResults && maxResults < MAX_SEARCH_RESULTS && <ShowMoreButton handleMoreResults={handleMoreResults}/>}
        </div>
      </div>
  );
};

export default ActionBar;
