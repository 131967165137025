import {default as React} from 'react' ;
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {
  Link,
} from "react-router-dom";

const PrivacyPolicy = () => {
  return (
    <Box sx={{padding: '40px'}}>
      <Typography variant="h2" gutterBottom component="div">
        Privacy Policy
      </Typography>

      <Typography variant="h3" gutterBottom component="div">
        Who we are
      </Typography>

      <Typography variant="body1" gutterBottom>
        Our website address is: <Link to='/'>https://supermarket101.uk/</Link>
      </Typography>

      <Typography variant="h3" gutterBottom component="div">
        What we collect
      </Typography>

      <Typography variant="body1" gutterBottom>
        To help us provide a fantastic service to you, we make use of Cookies and Local Storage. Some of the services that
        we use are: <a target='_blank' rel="noreferrer" href='https://analytics.google.com/analytics/'>Google Analytics</a>, <a target='_blank' rel="noreferrer" href='https://sentry.io/'>Sentry</a>.
        Our web server logs may contain the IP address of your Internet connection. We do not ask for or store any: names, addresses,
        phone numbers, credit cards, email addresses, etc.
      </Typography>

      <Typography variant="h3" gutterBottom component="div">
        Contact Us
      </Typography>

      <Typography variant="body1" gutterBottom>
        Any questions about your privacy? Then you can contact us directly at our email address <a href='mailto:info@supermarket101.uk'>info@supermarket101.uk</a>
      </Typography>
    </Box>
  );
};

export default PrivacyPolicy;
