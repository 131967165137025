import * as React from "react";
import {
  Link,
} from "react-router-dom";
import {
  STORE_NAMES,
  SUPERMARKET_NAME_ALDI,
  SUPERMARKET_NAME_ASDA,
  SUPERMARKET_NAME_MORRISONS, 
  SUPERMARKET_NAME_SAINSBURYS, 
  SUPERMARKET_NAME_TESCO, 
  SUPERMARKET_NAME_WAITROSE,
} from "../general/constants";

import {
  ACTION_QUERY_SEARCH,
} from '../actions/actions';

const Footer = ({dispatch})=>{
  const doQuery = (q) => dispatch({type: ACTION_QUERY_SEARCH, payload: {searchText: q}});

  return (
    <footer className="container-fluid footer">
      <div className="row">

        <div className="col-12 col-sm-4 footer-col">
          <h4>Supermarkets</h4>
          <Link onClick={() => doQuery(SUPERMARKET_NAME_ALDI)} to={`/shop/${encodeURIComponent(SUPERMARKET_NAME_ALDI)}/`}>Shop {STORE_NAMES[SUPERMARKET_NAME_ALDI]}</Link>
          <Link onClick={() => doQuery(SUPERMARKET_NAME_ASDA)} to={`/shop/${encodeURIComponent(SUPERMARKET_NAME_ASDA)}/`}>Shop {STORE_NAMES[SUPERMARKET_NAME_ASDA]}</Link>
          <Link onClick={() => doQuery(SUPERMARKET_NAME_MORRISONS)} to={`/shop/${encodeURIComponent(SUPERMARKET_NAME_MORRISONS)}/`}>Shop {STORE_NAMES[SUPERMARKET_NAME_MORRISONS]}</Link>
          <Link onClick={() => doQuery(SUPERMARKET_NAME_SAINSBURYS)} to={`/shop/${encodeURIComponent(SUPERMARKET_NAME_SAINSBURYS)}/`}>Shop {STORE_NAMES[SUPERMARKET_NAME_SAINSBURYS]}</Link>
          <Link onClick={() => doQuery(SUPERMARKET_NAME_TESCO)} to={`/shop/${encodeURIComponent(SUPERMARKET_NAME_TESCO)}/`}>Shop {STORE_NAMES[SUPERMARKET_NAME_TESCO]}</Link>
          <Link onClick={() => doQuery(SUPERMARKET_NAME_WAITROSE)} to={`/shop/${encodeURIComponent(SUPERMARKET_NAME_WAITROSE)}/`}>Shop {STORE_NAMES[SUPERMARKET_NAME_WAITROSE]}</Link>
        </div>

        <div className="col-12 col-sm-4 footer-col">
          <h4>Brands</h4>
          <Link onClick={() => doQuery('coca cola')} to={`/brand/${encodeURIComponent("coca cola")}`}>Shop Coca Cola</Link>
          <Link onClick={() => doQuery('kellogs')} to={`/brand/${encodeURIComponent("kellogs")}`}>Shop Kellogs</Link>
          <Link onClick={() => doQuery('cadbury')} to={`/brand/${encodeURIComponent("cadbury")}`}>Shop Cadbury</Link>
          <Link onClick={() => doQuery('walkers')} to={`/brand/${encodeURIComponent("walkers")}`}>Shop Walkers</Link>
          <Link onClick={() => doQuery('warburtons')} to={`/brand/${encodeURIComponent("warburtons")}`}>Shop Warburtons</Link>
          <Link onClick={() => doQuery('pringles')} to={`/brand/${encodeURIComponent("pringles")}`}>Shop Pringles</Link>
          <Link onClick={() => doQuery('smirnoff')} to={`/brand/${encodeURIComponent("smirnoff")}`}>Shop Smirnoff</Link>
        </div>

        <div className="col-12 col-sm-4 footer-col last-col">
          <h4>General</h4>
          <a href="/all.html">
            All Products
          </a>
          <Link to="/privacy-policy">
            Privacy Policy
          </Link>
          <Link to="/">
            &copy; {new Date().getFullYear()}, supermarket101.uk
          </Link>          
        </div>

      </div>
    </footer>
  );
};

export default Footer;
