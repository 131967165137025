import * as React from "react";
import {
  Link
} from "react-router-dom";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const Header = ({isMainPage=true})=>{

  return (
    <Grid
      id='my-header'
      sx={  {
        padding: '15px',
        backgroundColor: '#388e3c',
        color: 'white'
      } }
      container 
      direction="row"
      justifyContent="flex-end"
      alignItems="center">

        <Grid item xs={12} className='site-name'>
          <Link to='/'>
            <Typography sx={{color: '#fff', fontWeight: '100', textAlign: 'center', overflowWrap: 'break-word', fontSize: '66px',}} variant={isMainPage ? 'h1' : 'h2'} component={isMainPage ? 'h1' : 'h2'}>
              Supermarket101 <img src="/logo-64.png" alt="Logo" className="rounded-image" />
            </Typography>

            <Typography sx={{color: '#fff', fontWeight: '400', textAlign: 'center', overflowWrap: 'break-word',}} variant='h5' component="h5" gutterBottom>
              Choose favourites, compare prices, and swap to save big £££s effortlessly!
            </Typography>

            <Typography sx={{color: '#fff', fontWeight: '100', textAlign: 'left', overflowWrap: 'break-word',}} variant='h5' component="h5">
              Welcome to Supermarket101, your ultimate destination for savvy shopping! We take the hassle out of comparing prices across popular supermarkets such as Aldi, Asda, Morrisons, Sainsbury's, Tesco, and Waitrose. Our user-friendly platform empowers you to make informed decisions by providing real-time price comparisons on a wide range of products. Whether you're looking for groceries, household essentials, or special treats, Supermarket101 ensures you get the best deals without the legwork. Save time and money with our comprehensive and up-to-date information, helping you stretch your budget further. Start your smart shopping journey today - compare prices effortlessly and make every purchase count with Supermarket101.
            </Typography>            

          </Link>
        </Grid>

        <Grid item xs={12} lg={2} className='wobble'
          container justifyContent="center" alignItems="center">
            <h5>Please Like & Share</h5>
            <div className="fb-like" data-href="https://supermarket101.uk/" data-width=""
             data-layout="button_count" data-action="like" data-size="large" data-share="true"></div>
        </Grid>        

    </Grid>);
};

export default Header;
