import * as React from "react";
import { ReactComponent as CrossImage } from '../images/cross.svg';

const ModalDialog = props=>{
  return (
      <div
          key={props.dialogId}
          className="my-modal-dialog"
          onClick={e=>props.handleCloseDialog(props.dialogId)}
          >
        <div className="row">
          <div
              className={`close-dialog mb-2 col`}
              title="Close"
              aria-label="Close"
              >
            <CrossImage className="cross-img-50" />
          </div>
        </div>

        <div className="row">
          <div className={`main-dialog-col col col-12 col-md-${props.columns}`} onClick={e=>e.stopPropagation()} >
            {props.children}
          </div>
        </div>
      </div>
  );
};

export default ModalDialog;
