import * as React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHeart} from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as CrossImage } from '../images/cross.svg';

const AddToCartButton = ({productData,qtyInList,handleAddToList,handleRemoveFromList})=>{
  return (
    <div className="add-btn-container">

      <div className="add-remove-ctn">
        <button
          type="button"
          className="add-to-list btn btn-success"
          title="Add 1 to Favourites"
          aria-label="Add 1 to Favourites"
          onClick={e=>handleAddToList(e,productData)}><FontAwesomeIcon icon={faHeart} /> Add
          {
            qtyInList > 0 &&
            <div className="qty-count">{qtyInList}</div>
          }
        </button>

        {
          qtyInList > 0 &&
          <div className="remove-from-list" title="Remove 1 from Favourites" aria-label="Remove 1 from Favourites" onClick={e=>handleRemoveFromList(e,productData)}>
            <CrossImage className="cross-img-24" />
          </div>
        }
      </div>

    </div>
  );
};

export default AddToCartButton;
