import * as React from "react";
import Product from "./Product";
import { ReactComponent as BusyImage } from '../images/busy.svg';

const ProductList = ({busy,products,handleAddToList,myList,handleRemoveFromList,handleShowProductDetails})=>{
  const allProducts = products.map(p=>{
    const qtyInList = p.id in myList ? myList[p.id].qty : 0;
    return (<Product key={p.id} productData={p} handleAddToList={handleAddToList} qtyInList={qtyInList} handleRemoveFromList={handleRemoveFromList} handleShowProductDetails={handleShowProductDetails} />)
    }
  );

  return (
    <div id="product-list" className="product-list row">
      {busy && <div className="busy-notice"><BusyImage /></div>}
      {
        products.length === 0
        ?
        <div key="no-results" className="col col-12 no-results-col">
          <h2 className="no-results-found">No Results Found</h2>
        </div>
        :
        allProducts
      }
    </div>
  );
};

export default ProductList;
