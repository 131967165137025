import { default as React, useState, useEffect, useRef} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRedo} from "@fortawesome/free-solid-svg-icons";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import SearchBar from "./SearchBar";
import ProductList from "./ProductList";
import ActionBar from "./ActionBar";
import ModalDialog from "./ModalDialog";
import ProductPanel from "./ProductPanel";
import {
  ACTION_BUSY,
  ACTION_CLEAR_BUSY,
  ACTION_NEW_SEARCH,
  ACTION_RESULTS,
  ACTION_ERROR,
  ACTION_CLOSE_DIALOG,
  ACTION_CATEGORY_TOGGLE,
  ACTION_CATEGORY_RESET,
  ACTION_ADD_TO_LIST,
  ACTION_REMOVE_FROM_LIST,
  ACTION_SORT_BY,
  ACTION_STORE_CHANGE,
  ACTION_ON_OFFER_CHANGE,
  ACTION_OPEN_PRODUCT_DETAILS,
  ACTION_OPEN_DIALOG,
  ACTION_EMPTY_CART,
  ACTION_SWAP
} from "../actions/actions";
import {IO_EVENT_DO_SEARCH, IO_EVENT_SEARCH_ERROR, IO_EVENT_SEARCH_RESULTS} from "../io-events/io-events";
import {
  DIALOG_ID_PRODUCT_DETAIL,
  DIALOG_ID_SELECT_CATEGORY,
  DIALOG_ID_SHOPPING_LIST,
  TITLE,
  CATEGORY_GENERAL,
  CATEGORY_HALLOWEEN,
  CATEGORY_XMAS,
  CATEGORY_NEW_YEAR,
  CATEGORY_DIETARY_LIFESTYLE,
  CATEGORY_FRUIT_VEG,
  CATEGORY_MEAT_FISH,
  CATEGORY_DAIRY_EGGS_CHILLED,
  CATEGORY_BAKERY,
  CATEGORY_FROZEN,
  CATEGORY_FOOD_CUPBOARD,
  CATEGORY_DRINKS,
  CATEGORY_HOUSEHOLD,
  CATEGORY_TOILETRIES_HEALTH,
  CATEGORY_TOYS,
  CATEGORY_HOMEWARE_OUTDOORS,
  CATEGORY_BABY_TODDLER,
  CATEGORY_PET,
  CATEGORY_FRESH_FOOD,
  SHOP_LOGOS, 
  STORE_NAMES,
  SUPERMARKET_NAME_ALDI,
  SUPERMARKET_NAME_ASDA,
  SUPERMARKET_NAME_MORRISONS,
  SUPERMARKET_NAME_SAINSBURYS,
  SUPERMARKET_NAME_TESCO,
  SUPERMARKET_NAME_WAITROSE
  } from "../general/constants";
import CheckboxPanel from "./CheckboxPanel";
import {ucFirstWords, calculateCartTotals} from "../general/utils";
import ShoppingListDialog from "./ShoppingListDialog";

import './FilterableProductList.css';

const FilterableProductList = ({state,dispatch,socketIO})=>{
  const [busyTimerId, setBusyTimerId] = useState(null);

  const firstRef = useRef(true);
  const secondRef = useRef(true);
  const fourthRef = useRef(true);

  const categoryOptions = [];
  const activeCategoryFilters = [];
  const categoryCountMap = new Map();

  if( state.resultsNoFacetSearch.facet_counts.facet_fields.hasOwnProperty("category_name") ){
    for(let a=0; a < state.resultsNoFacetSearch.facet_counts.facet_fields.category_name.length; a+=2){
      const catName = state.resultsNoFacetSearch.facet_counts.facet_fields.category_name[a];
      const catCount = state.resultsNoFacetSearch.facet_counts.facet_fields.category_name[a+1];
      categoryCountMap.set(catName, catCount);
    }
  }

  for(const [categoryName, filterOnCategory] of Object.entries(state.filterByCategory)) {
    const count = categoryCountMap.get(categoryName) ?? 0;
    const isDisabled = count === 0;
    categoryOptions.push({name: categoryName, checked: filterOnCategory, disabled: isDisabled, count: count});

    if(filterOnCategory){
      activeCategoryFilters.push(ucFirstWords(categoryName));
    }
  }

  categoryOptions.sort((a,b)=>{
    return a.name.localeCompare(b.name);
  });

  activeCategoryFilters.sort((a,b)=>{
    return a.localeCompare(b);
  });

  const onPopState = function (){
    // eslint-disable-next-line
    window.location.href = window.location.href;
  };

  const onScrollHandler = function (){
    const headerRow = document.getElementById("my-header");
    const searchBar = document.getElementById("search-bar");

    if(searchBar === null){
      return;
    }

    const productList = document.getElementById("product-list");
    const changeStore = document.querySelector(".cat-col .change-store");

    if (window.pageYOffset >= (headerRow.offsetHeight)  ) {
      searchBar.classList.add("sticky");
      productList.style.paddingTop = searchBar.offsetHeight+"px";
      changeStore.style.paddingTop = searchBar.offsetHeight+"px";
    } else {
      searchBar.classList.remove("sticky");
      productList.style.paddingTop = "0px";
      changeStore.style.paddingTop = "0px";
    }
  };

  const searchCriteria = ( ({searchText, maxResults, filterByCategory, sortBy, store, onOffer}) => ({searchText, maxResults, filterByCategory, sortBy, store, onOffer}) )(state);

  useEffect(()=>{

      if(fourthRef.current) {
        fourthRef.current = false;
        return;
      }

      if( !state.busy && busyTimerId !== null ){
        clearTimeout(busyTimerId);
        setBusyTimerId(null);
      }

      if(!state.busy){
        return;
      }

      const timerId = setTimeout(()=>dispatch({type: ACTION_CLEAR_BUSY}), 5000);
      setBusyTimerId(timerId);

    },
    // eslint-disable-next-line
    [
      state.busy
    ]
  );

  useEffect( ()=>{
    //
    // Don't need this as SSR will have handled this in the first load.
    //
    if(firstRef.current) {
      firstRef.current = false;
      return;
    }

    if( state.dialogsVisible[DIALOG_ID_PRODUCT_DETAIL] && (state.viewingProduct !== null) ){
      // Product details is showing with a product.

      const title = `${state.viewingProduct.product_name}`;

      // eslint-disable-next-line no-restricted-globals
      history.pushState(
        null,
        title,
        encodeURI(`/product/${state.viewingProduct.product_name}/${state.viewingProduct.id}`)
      );

      document.title = title;

    }else if( state.dialogsVisible[DIALOG_ID_PRODUCT_DETAIL] === false ) {

      // Set title / URL back to root.

      // eslint-disable-next-line no-restricted-globals
      history.pushState(null, TITLE, `/`);
      document.title = TITLE;
    }
  },
        // eslint-disable-next-line
  [state.dialogsVisible[DIALOG_ID_PRODUCT_DETAIL], state.viewingProduct]);

  useEffect(()=>{
    socketIO.on( IO_EVENT_SEARCH_RESULTS, r => {
      dispatch({type: ACTION_RESULTS, payload: r});
      document.title = TITLE;
    });

    socketIO.on( IO_EVENT_SEARCH_ERROR, e => {
      dispatch({type: ACTION_ERROR, payload: e});
    });

    return ()=>{
      socketIO.off( IO_EVENT_SEARCH_RESULTS);
      socketIO.off( IO_EVENT_SEARCH_ERROR);
    };

  },
    // eslint-disable-next-line
  []);

  useEffect(()=>{
    //
    // Don't need this as SSR will have handled this in the first load.
    //
    if(secondRef.current) {
      secondRef.current = false;
      return;
    }

    dispatch({type:ACTION_BUSY});
    socketIO.emit(IO_EVENT_DO_SEARCH, searchCriteria );
  },
    // eslint-disable-next-line
  [
    state.searchToggle,
    state.onOffer,
    state.store,
    state.sortBy,
    state.searchText,
    state.maxResults,
    state.filterByCategory[CATEGORY_GENERAL], // eslint-disable-line
    state.filterByCategory[CATEGORY_HALLOWEEN], // eslint-disable-line
    state.filterByCategory[CATEGORY_XMAS], // eslint-disable-line
    state.filterByCategory[CATEGORY_NEW_YEAR], // eslint-disable-line
    state.filterByCategory[CATEGORY_DIETARY_LIFESTYLE], // eslint-disable-line
    state.filterByCategory[CATEGORY_FRUIT_VEG], // eslint-disable-line
    state.filterByCategory[CATEGORY_MEAT_FISH], // eslint-disable-line
    state.filterByCategory[CATEGORY_DAIRY_EGGS_CHILLED], // eslint-disable-line
    state.filterByCategory[CATEGORY_BAKERY], // eslint-disable-line
    state.filterByCategory[CATEGORY_FROZEN], // eslint-disable-line
    state.filterByCategory[CATEGORY_FOOD_CUPBOARD], // eslint-disable-line
    state.filterByCategory[CATEGORY_DRINKS], // eslint-disable-line
    state.filterByCategory[CATEGORY_HOUSEHOLD], // eslint-disable-line
    state.filterByCategory[CATEGORY_TOILETRIES_HEALTH], // eslint-disable-line
    state.filterByCategory[CATEGORY_TOYS], // eslint-disable-line
    state.filterByCategory[CATEGORY_HOMEWARE_OUTDOORS], // eslint-disable-line
    state.filterByCategory[CATEGORY_BABY_TODDLER], // eslint-disable-line
    state.filterByCategory[CATEGORY_PET], // eslint-disable-line
    state.filterByCategory[CATEGORY_FRESH_FOOD], // eslint-disable-line
    ]);

  useEffect(()=>{
    window.removeEventListener('scroll', onScrollHandler);
    window.onscroll = onScrollHandler;

    window.removeEventListener('popstate', onPopState);
    window.addEventListener('popstate', onPopState);

    return () => {
      window.removeEventListener('scroll', onScrollHandler);
      window.removeEventListener('popstate', onPopState);
    }
  }, []);

  useEffect(()=>{
    let anyDialogVisible = false;

    for(const dialogVisible of Object.values(state.dialogsVisible) ) {
      if(dialogVisible){
        anyDialogVisible = true;

        try{
          document.body.classList.add("hide-scroll");
        }catch(e){}
      }
    }

    if(!anyDialogVisible){
      try{
        document.body.classList.remove("hide-scroll");
      }catch(e){}
    }

    return ()=>{
      try{
        document.body.classList.remove("hide-scroll");
      }catch(e){}
    };
  }, [state.dialogsVisible]);

  const handleReset = ()=>{
    dispatch({type: ACTION_CATEGORY_RESET});
  };

  const handleCategoryChange = e=>{
    dispatch({type: ACTION_CATEGORY_TOGGLE, payload: {name: e.name, checked: e.checked} });
  };

  const handleAddToList = (e, productData)=>{
    e.preventDefault();
    e.stopPropagation();
    dispatch({type: ACTION_ADD_TO_LIST, payload: productData });
    toast("Added 1 '"+ucFirstWords(productData.supermarket_name)+" - "+productData.product_name+"' to Favourites");
  };

  const handleRemoveFromList = (e, productData)=>{
    e.preventDefault();
    e.stopPropagation();
    dispatch({type: ACTION_REMOVE_FROM_LIST, payload: productData });
    toast("Removed 1 '"+ucFirstWords(productData.supermarket_name)+" - "+productData.product_name+"' from Favourites");
  };

  const handleSortChange = sortBy => dispatch( {type:ACTION_SORT_BY, payload: sortBy} );

  const handleStoreChange = store => dispatch( {type:ACTION_STORE_CHANGE, payload: store} );

  const handleOnOfferChange = onOffer => dispatch( {type:ACTION_ON_OFFER_CHANGE, payload: onOffer} );

  const handleNewSearch = () => dispatch({type: ACTION_NEW_SEARCH});

  const changeStore = (
    <>
      <h4 className="change-store">Change Store</h4>

      <select autoComplete="off" className="store-select" name="store-select" value={state.store} onChange={e=>handleStoreChange(e.target.value)}>
        <option key="NONE" value="">Any Supermarket</option>
        <option key={SUPERMARKET_NAME_ALDI} value={SUPERMARKET_NAME_ALDI}>{STORE_NAMES[SUPERMARKET_NAME_ALDI]}</option>
        <option key={SUPERMARKET_NAME_ASDA} value={SUPERMARKET_NAME_ASDA}>{STORE_NAMES[SUPERMARKET_NAME_ASDA]}</option>
        <option key={SUPERMARKET_NAME_MORRISONS} value={SUPERMARKET_NAME_MORRISONS}>{STORE_NAMES[SUPERMARKET_NAME_MORRISONS]}</option>
        <option key={SUPERMARKET_NAME_SAINSBURYS} value={SUPERMARKET_NAME_SAINSBURYS}>{STORE_NAMES[SUPERMARKET_NAME_SAINSBURYS]}</option>
        <option key={SUPERMARKET_NAME_TESCO} value={SUPERMARKET_NAME_TESCO}>{STORE_NAMES[SUPERMARKET_NAME_TESCO]}</option>
        <option key={SUPERMARKET_NAME_WAITROSE} value={SUPERMARKET_NAME_WAITROSE}>{STORE_NAMES[SUPERMARKET_NAME_WAITROSE]}</option>
      </select>

      <div className="store-image-container">
        {SHOP_LOGOS.get(state.store)}
      </div>
    </>
  );

  const checkBoxPanel = (
    <>
      <h4 id="cat-heading" className="cat-heading">Categories</h4>
      <CheckboxPanel options={categoryOptions} handleChange={handleCategoryChange} handleReset={handleReset}/>
    </>
  );

  const newSearchBtn = (
    <button onClick={handleNewSearch} type="button" className="btn btn-danger new-search" title="New Search"><FontAwesomeIcon icon={faRedo} /> New Search</button>
  );

  const catCol = (
    <>
      {changeStore}

      {newSearchBtn}

      <div className="row checkbox-row on-offer-row">
        <div className="col">
          <div className={`checkbox-container`}>
            <label htmlFor="on-offer">On Promotion</label>
            <input
              className="my-checkbox-input"
              type="checkbox"
              id="on-offer"
              name="on-offer"
              value={state.onOffer}
              checked={state.onOffer ? "checked" : ""}
              onChange={e => handleOnOfferChange(e.target.checked) }
            />
          </div>
        </div>
      </div>

      {checkBoxPanel}
    </>
  );

  const catColDialog = (
    <>
      {changeStore}

      {newSearchBtn}

      <div className="row checkbox-row on-offer-row">
        <div className="col">
          <div className={`checkbox-container`}>
            <label htmlFor="on-offer-dialog">On Promotion</label>
            <input
              className="my-checkbox-input"
              type="checkbox"
              id="on-offer-dialog"
              name="on-offer"
              value={state.onOffer}
              checked={state.onOffer ? "checked" : ""}
              onChange={e => handleOnOfferChange(e.target.checked) }
            />
          </div>
        </div>
      </div>

      {checkBoxPanel}
    </>
  );

  const handleCloseDialog = dialogId=>dispatch({type: ACTION_CLOSE_DIALOG, payload: dialogId});
  const handleShowProductDetails = productData=>dispatch({type: ACTION_OPEN_PRODUCT_DETAILS, payload: productData});

  const handleOpenList = () => dispatch({type: ACTION_OPEN_DIALOG, payload: {dialogId: DIALOG_ID_SHOPPING_LIST} });
  const handleCloseList = () => dispatch({type: ACTION_CLOSE_DIALOG, payload: DIALOG_ID_SHOPPING_LIST});
  const emptyCart = () => {
    if(window.confirm("Remove all products from favourites?")){
      dispatch({type: ACTION_EMPTY_CART, payload: {dialogId: DIALOG_ID_SHOPPING_LIST}});
      return true;
    }

    return false;
  };
  const handleSwap = (altProducts) => {
    dispatch({type: ACTION_SWAP, payload: {
      altProducts
    }});
  };

  const totals = calculateCartTotals(state.myList);
  const items = Object.keys(state.myList)
    .reduce( (acc, val) => {
      acc.push(state.myList[val]);
      return acc;
    }, [] );

  const shoppingListDialog = (
    <ShoppingListDialog 
      open={state.dialogsVisible[DIALOG_ID_SHOPPING_LIST]}
      handleCloseList={handleCloseList}
      total={totals.totalPrice.toFixed(2)}
      items={items}
      emptyCart={emptyCart}
      socketIO={socketIO}
      handleAddToList={handleAddToList}
      handleRemoveFromList={handleRemoveFromList}
      myList={state.myList}
      handleSwap={handleSwap}
      toast={toast}
    />
  );

  return (
      <div className="filterable-product-list">

        <ToastContainer
          position="bottom-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />

        {state.errorMsg
          && <div key="error-msg" className="error-msg">{`${state.errorMsg}`}</div>}

        {state.dialogsVisible[DIALOG_ID_SELECT_CATEGORY]
          &&  <ModalDialog columns={4} dialogId={DIALOG_ID_SELECT_CATEGORY} handleCloseDialog={handleCloseDialog}>
                {catColDialog}
              </ModalDialog>}

        {state.dialogsVisible[DIALOG_ID_SHOPPING_LIST] && shoppingListDialog}

        {state.viewingProduct !== null && state.dialogsVisible[DIALOG_ID_PRODUCT_DETAIL]
          && <ModalDialog columns={12} dialogId={DIALOG_ID_PRODUCT_DETAIL} handleCloseDialog={handleCloseDialog}>
            <ProductPanel handleShowProductDetails={handleShowProductDetails} altProductsInitial={state.altProducts} socketIO={socketIO} productData={state.viewingProduct} myList={state.myList} handleAddToList={handleAddToList} qtyInList={1} handleRemoveFromList={handleRemoveFromList} />
          </ModalDialog>}

        <SearchBar 
          handleSortChange={handleSortChange} 
          sortBy={state.sortBy} 
          searchText={state.searchText} 
          dispatch={dispatch} 
          activeCategoryFilters={activeCategoryFilters} 
          myList={state.myList}
          handleOpenList={handleOpenList}
          />

        <div key="main-row" className="row main-row">

          <div className="col d-none d-xl-block col-xl-2 cat-col">
            {catCol}
          </div>

          <div className="col col-12 col-xl-10">
            <ProductList
              busy={state.busy}
              products={state.results.response.docs}
              handleAddToList={handleAddToList}
              handleRemoveFromList={handleRemoveFromList}
              myList={state.myList}
              handleShowProductDetails={handleShowProductDetails}
              />

            {state.results.response.docs.length > 0
              && <ActionBar hasResults={state.results.response.docs.length > 0 && state.results.response.numFound > state.maxResults} maxResults={state.maxResults} handleMoreResults={dispatch} />
            }
          </div>

        </div>

      </div>
  );
};

export default FilterableProductList;
export {DIALOG_ID_PRODUCT_DETAIL,DIALOG_ID_SELECT_CATEGORY,DIALOG_ID_SHOPPING_LIST};
