import * as React from "react";
import {useEffect, useState} from "react";
import {DEFAULT_IMAGE,MY_SUPERMARKET_HOST} from "../general/constants";
import {SHOP_LOGOS} from "../general/constants";
import AddToCartButton from "./AddToCartButton";
import {
  IO_EVENT_ALT_PRODUCT_SEARCH,
  IO_EVENT_ALT_PRODUCT_SEARCH_RESULTS,
} from "../io-events/io-events";
import {buildProductLink, generateStructuredData} from "../general/utils";

const ProductPanel = ({altProductsInitial=null,socketIO,productData,myList,handleAddToList,handleRemoveFromList,handleShowProductDetails})=>{
  const [altProducts, setAltProducts] = useState(altProductsInitial ?? []);

  const productLink = `${MY_SUPERMARKET_HOST}${buildProductLink(productData)}`;

  const qtyInList = productData.id in myList ? myList[productData.id].qty : 0;

  const altProductComps = altProducts.map(altProduct => {
    const altQtyInList = altProduct.id in myList ? myList[altProduct.id].qty : 0;

    const altProductLink = buildProductLink(altProduct);

    return (
      <a key={altProduct.id} className="alt-product row" href={altProductLink} onClick={e=>{
        e.preventDefault();
        handleShowProductDetails(altProduct);
      }}>

        <script type="application/ld+json" dangerouslySetInnerHTML={{__html: generateStructuredData(altProduct)}}></script>

        <div className="shop-logo col-12 col-sm-6 col-xl-2 flex-center">
          {SHOP_LOGOS.get(altProduct.supermarket_name)}
        </div>
        <div className="alt-product-image col-12 col-sm-6 col-xl-2 flex-center">
          <img src={`${altProduct.product_image}`} alt={altProduct.product_name} onError={e=>{if(!e.target.src.endsWith(DEFAULT_IMAGE)){e.target.src=DEFAULT_IMAGE;}}} />
        </div>
        <div className="alt-product-title col-12 col-xl-4 flex-center">{altProduct.product_name}</div>
        <div className="alt-product-price col-12 col-xl-1 flex-center">{`£${altProduct.product_price.toFixed(2)}`}</div>

        <div className="btn-container col col-12 col-xl-3 flex-center">
          <AddToCartButton handleAddToList={handleAddToList} qtyInList={altQtyInList} handleRemoveFromList={handleRemoveFromList} productData={altProduct}/>
        </div>

      </a>
    );
  });

  useEffect(()=>{
    socketIO.on( IO_EVENT_ALT_PRODUCT_SEARCH_RESULTS, r => {
      setAltProducts(r.altProducts);
    });

    return ()=>{
      socketIO.off( IO_EVENT_ALT_PRODUCT_SEARCH_RESULTS);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(()=>{
    if(altProductsInitial === null){
      socketIO.emit(IO_EVENT_ALT_PRODUCT_SEARCH, {productData: productData} );
    }
  },
    // eslint-disable-next-line
  [productData.id]);

  return (
      <div className="product-panel" onClick={e=>e.stopPropagation()} >

        <script type="application/ld+json" dangerouslySetInnerHTML={{__html: generateStructuredData(productData)}}></script>

        <div className="row">

          <div className="col col-12 col-xl-6 product-image-col">

            <div className="product-detail-image">
              <img src={`${productData.product_image}`} alt={productData.product_name} onError={e=>{if(!e.target.src.endsWith(DEFAULT_IMAGE)){e.target.src=DEFAULT_IMAGE;}}} />
            </div>

            <div className="shop-logo">
              {SHOP_LOGOS.get(productData.supermarket_name)}
            </div>

            {productData.hasOwnProperty("product_promotion")
            &&
            <div className="latest-deal">
              <div title={`${productData.product_promotion}`} className="percentage-off">{productData.product_promotion}</div>
            </div>
            }

            <h1 className="product-title">{productData.product_name}</h1>

            <h5 className="product-price">{`£${productData.product_price.toFixed(2)}`}</h5>

            <div key={productLink}>
              <iframe
                title={`${productData.product_name}`}
                src={`https://www.facebook.com/plugins/share_button.php?href=${encodeURIComponent(productLink)}&layout=button&size=large&width=77&height=28&appId`}
                width="77" height="28" style={{border: "none", overflow: "hidden"}} scrolling="no" frameBorder="0"
                allowFullScreen={true}
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"/>
            </div>

            {productData.product_description && <p dangerouslySetInnerHTML={{__html: productData.product_description}} className="product-description"></p>}

            <AddToCartButton handleAddToList={handleAddToList} qtyInList={qtyInList} handleRemoveFromList={handleRemoveFromList} productData={productData}/>

          </div>

          <div className="col col-12 col-xl-6">

            <h2>Matching Supermarket Products</h2>

            <div className="alt-products">
              {altProductComps}
            </div>

          </div>

        </div>

      </div>
  );
};

export default ProductPanel;
