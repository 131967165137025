import * as React from "react";
import { useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Badge from '@mui/material/Badge';

import Confetti from 'react-confetti';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faFilter} from "@fortawesome/free-solid-svg-icons";
import {faHeart} from "@fortawesome/free-solid-svg-icons";

import {DIALOG_ID_SELECT_CATEGORY} from "./FilterableProductList";
import {ACTION_SEARCH, ACTION_OPEN_DIALOG, ACTION_CATEGORY_RESET} from "../actions/actions";

import { ReactComponent as CrossImage } from '../images/cross.svg';
import { ReactComponent as SearchImage } from '../images/search.svg';

import './SearchBar.css';

const SearchBar = ({sortBy="latest-deals",handleSortChange,searchText, dispatch, activeCategoryFilters, myList, handleOpenList})=>{
  const [confetti, setConfetti] = useState(false);

  const stringList = JSON.stringify(myList);

  useEffect(()=>{
    if(Object.keys(myList).length > 0){
      setConfetti(true);
    }
    // eslint-disable-next-line
  }, [stringList])

  const activeCategoryFiltersBlock = activeCategoryFilters.map((f,index)=>{
    return (
      <h6 key={f} className="active-filter" title={`Filtering on ${f}`}>{f}</h6>
    );
  });

  return (
    <>
    <Divider sx={{backgroundColor: '#fff'}}/>
    <div key="search-bar" id="search-bar" className="search-bar row">

      <div className="filter-by-category col-6 col-xl-2 order-2 order-xl-1">
        <div className="filter-block">
          <div className="select-btn-container">

            <button
              type="button"
              className="select-category-btn btn btn-dark"
              onClick={() => dispatch({type: ACTION_OPEN_DIALOG, payload: {dialogId: DIALOG_ID_SELECT_CATEGORY} })}><FontAwesomeIcon icon={faFilter} /> Filter Results
              {
                activeCategoryFiltersBlock.length > 0 &&
                <div className="filter-count">{activeCategoryFiltersBlock.length}</div>
              }
            </button>

            {
              activeCategoryFiltersBlock.length > 0 &&
              <div className="clear-category-filter" title="Clear Category Filters" aria-label="Clear Category Filters" onClick={e=>dispatch({type: ACTION_CATEGORY_RESET})}>
                <CrossImage className="cross-img-24" />
              </div>
            }

          </div>
          <h6 className="active-category-filters-label">{activeCategoryFiltersBlock.length > 0 && `Filtering on: `}</h6>
          <div className="active-category-filters">{activeCategoryFiltersBlock.length > 0 && activeCategoryFiltersBlock}</div>
        </div>
      </div>

      <div className="col-12 col-xl-5 order-1 order-xl-2 search-text-col">
        <div className="row no-gutters">

          <div className='col-1'>
            <div  
              style={{maxHeight: "56px"}}
              className='searchImageContainer'>
              <SearchImage />
            </div>
          </div>

          <div className="col-11">

            <TextField
              sx={{backgroundColor: "white", width: "100%"}}
              variant="filled"
              label="Search For Products"
              placeholder="e.g. eggs, milk, bread"
              autoFocus
              onChange={(e) => dispatch({type: ACTION_SEARCH, payload: {searchText: e.target.value}})}
              value={searchText}
            />

          </div>

        </div>
      </div>

      <div className="shopping-list col-6 col-xl-3 order-3 order-xl-4">
        

          <Badge anchorOrigin={{ vertical: "top", horizontal: "right" }} badgeContent={ Object.keys(myList)?.length } color="error">          
            <button              
              type="button"
              className="btn btn-primary"
              title="Compare Prices"
              aria-label="Compare Prices"
              onClick={()=>handleOpenList()}>
                <Confetti 
                  width={157} 
                  height={100} 
                  numberOfPieces={confetti ? 500 : 0} 
                  recycle={false}
                  tweenDuration={1000}
                  onConfettiComplete={(c) =>{
                    setConfetti(false);
                    c.reset();
                  }}
                  />
                Compare Prices <FontAwesomeIcon icon={faHeart} />
            </button>
          </Badge>
      </div>

      <div className="sort-col col-12 col-xl-2 order-5 order-xl-5">
        <label htmlFor="sort-by">Sort By</label>
        <select autoComplete="off" id="sort-by" name="sort-by" value={sortBy} onChange={e=>handleSortChange(e.target.value)}>
          <option key="latest-deals" value="latest-deals">Relevance & Newest Promotions</option>
          <option key="price" value="price">Relevance & Price</option>
        </select>
      </div>

    </div>
    </>
  );
};

export default SearchBar;
