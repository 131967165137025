import { default as React } from 'react';
import {useEffect, useState} from "react";
import Dialog from '@mui/material/Dialog';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import AppBar from '@mui/material/AppBar';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Toolbar from '@mui/material/Toolbar';
import Fab from '@mui/material/Fab';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashCan,faTrophy,faExchange} from "@fortawesome/free-solid-svg-icons";
import { cloneDeep } from 'lodash';
import Confetti from 'react-confetti';
import AddToCartButton from "./AddToCartButton";

import {
  IO_EVENT_ALT_PRODUCT_SEARCH,
  IO_EVENT_ALT_PRODUCT_SEARCH_RESULTS,
} from "../io-events/io-events";

import {DEFAULT_IMAGE, SHOP_LOGOS} from "../general/constants";

import './ShoppingListDialog.css';

const ShoppingListDialog = ({open, handleCloseList, total, items, emptyCart, socketIO, handleAddToList, handleRemoveFromList, myList, handleSwap, toast}) => {
  const [altProducts, setAltProducts] = useState({});  
  const [savings, setSavings] = useState(0.0);
  const [confetti, setConfetti] = useState(false);

  const stringItems = JSON.stringify(items);
  const stringAltProducts = JSON.stringify(altProducts);

  useEffect(()=>{
    if(items.length === 0){
      return;
    }

    setConfetti(true);
    // eslint-disable-next-line
  }, [stringItems])

  const productData = items.map(i => i.product);

  useEffect(()=>{
    socketIO.on( IO_EVENT_ALT_PRODUCT_SEARCH_RESULTS, r => {
      setAltProducts(r.altProducts);
    });

    return ()=>{
      socketIO.off( IO_EVENT_ALT_PRODUCT_SEARCH_RESULTS);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(()=>{
    socketIO.emit(IO_EVENT_ALT_PRODUCT_SEARCH, {productData: productData} );
  },
    // eslint-disable-next-line
  [stringItems]);

  useEffect(()=>{
    if(Object.keys(altProducts).length === 0){
      return;
    }

    let cheapestTotal = 0;
    let total = 0;

    for(const item of items){
      const alts = altProducts[item.product.id] || [];

      if(alts.length === 0){
        return;
      }

      const sortedCheapest = cloneDeep(alts).sort( (a, b) => a.product_price - b.product_price );
      const cheapest = sortedCheapest[0];

      total += item.qty * item.product.product_price;
      cheapestTotal += item.qty * cheapest.product_price;
    }

    setSavings((Math.round(((total - cheapestTotal) + Number.EPSILON) * 100) / 100).toFixed(2));
  },
    // eslint-disable-next-line
  [stringItems, stringAltProducts]);

  const sortedItems = cloneDeep(items).sort( (a, b) => a.product.supermarket_name.localeCompare(b.product.supermarket_name) );

  const renderListItem = (item, index) => {
    const qtyInList = item.product.id in myList ? myList[item.product.id].qty : 0;
    const alts = altProducts[item.product.id] || [];

    const altPrices = cloneDeep(alts).sort( (a, b) => a.supermarket_name.localeCompare(b.supermarket_name) ).map(a => {
      const sortedCheapest = cloneDeep(alts).sort( (a, b) => a.product_price - b.product_price );
      const cheapest = sortedCheapest[0].id === a.id;
      const boxStyle = cheapest ? {padding: '5px', backgroundColor: 'gold', borderRadius: '10px', border: "1px solid black",} : {};
      const opacity = cheapest ? 1 : 0.25;

      return (
        <Grid
          key={a.id} 
          item
          xs={6} xl={1}
          sx={{padding: '5px', textAlign: 'center', opacity}} 
          >
            <Box sx={boxStyle}>
              <Box className='productImage' sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                {SHOP_LOGOS.get(a.supermarket_name)}
              </Box>
  
              <Typography variant="h6" gutterBottom component="div">
                £{(a.product_price).toFixed(2)}
              </Typography>
  
              {cheapest && <Chip sx={{border: "1px solid black", fontWeight: "900"}} icon={<FontAwesomeIcon icon={faTrophy} />} label="Cheapest" variant="elevated  " />}
            </Box>
        </Grid>
      );
    });

    return (
      <Box key={`${item.product.id}-${index}`}>
        <ListItem>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center">

              <Grid 
                item
                xs={12} xl={1}
                sx={{paddingLeft: '15px', paddingRight: '15px'}} 
                >
                <Box className='productImage' sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  {SHOP_LOGOS.get(item.product.supermarket_name)}
                </Box>
              </Grid>

              <Grid 
                item
                xs={12} xl={1} 
                sx={{paddingLeft: '15px', paddingRight: '15px'}}
                >
                <Box className='productImage' sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  <img 
                    src={item.product.product_image} 
                    alt={item.product.product_name}
                    onError={e=>{if(!e.target.src.endsWith(DEFAULT_IMAGE)){e.target.src=DEFAULT_IMAGE;}}} />
                </Box>
              </Grid>
              
              <Grid 
                item      
                xs={12} xl={3}            
                sx={{paddingLeft: '15px', paddingRight: '15px'}}
                >
                <Typography variant="h6" gutterBottom component="div">
                  {item.product.product_name}
                </Typography>
              </Grid>

              <Grid 
                item 
                xs={12} xl={1} 
                sx={{paddingLeft: '15px', paddingRight: '15px'}}
                >
                  <Typography variant="h6" gutterBottom component="div">
                    £{(item.product.product_price).toFixed(2)}
                  </Typography>
                  <AddToCartButton 
                    handleAddToList={handleAddToList} 
                    qtyInList={qtyInList} 
                    handleRemoveFromList={handleRemoveFromList} 
                    productData={item.product}/>
              </Grid>

              {altPrices}

          </Grid>
        </ListItem>
        <Divider />
      </Box>
    );
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleCloseList}
      className='sld-heading'
      onClick={(e)=>e.stopPropagation()}>
        
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingTop: '15px', paddingBottom: '15px',}}>

        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center">

          <Grid 
            item 
            xs={12} xl={2} sx={{textAlign: 'center'}}>
              <Fab
                color="primary"
                onClick={handleCloseList}
                aria-label="close">
                <CloseIcon />
              </Fab>
            </Grid>

            <Grid 
              item 
              xs={12} xl={2} sx={{textAlign: 'center'}}>
              <Typography sx={{padding: '15px', fontWeight: "900"}} variant="h4" gutterBottom component="div">
                My Favourites
              </Typography>
            </Grid>
            
            <Grid 
              item 
              xs={12} xl={2} sx={{textAlign: 'center'}}>
              <Typography variant="h4" gutterBottom component="div">
                Total = £{total}
              </Typography>
            </Grid>

            <Grid 
              item 
              xs={12} xl={4} sx={{textAlign: 'center', padding: "5px"}}
              >
              {items.length > 0 && Object.keys(altProducts).length > 0 && !(savings > 0) &&
                <Button 
                  onClick={()=>{}} 
                  variant="contained" 
                  color="success" 
                  sx={{cursor: "default !important", padding: "15px", textTransform: "none", fontSize: "18px"}}>   
                  <Confetti 
                    width={559} 
                    height={100} 
                    numberOfPieces={100} 
                    recycle={true}
                    tweenDuration={1000}
                    onConfettiComplete={(c) =>{
                      c.reset();
                    }}
                  />            
                  <Typography variant="h5">                    
                    <FontAwesomeIcon icon={faTrophy} /> Congratulations, all your favourites are cheapest across Aldi, Asda, Morrisons, Sainsbury's, Tesco, and Waitrose
                  </Typography>
                </Button>
              }
              {items.length > 0 && Object.keys(altProducts).length > 0 && savings > 0 &&                
                <Button onClick={()=>{
                      const cloneAltProducts = cloneDeep(altProducts);
                      setAltProducts([]);
                      handleSwap(cloneAltProducts); 
                      toast("Swaped for cheapest products");
                    }} variant="contained" color="success" sx={{padding: "15px", textTransform: "none", fontSize: "18px"}}>
                  <Confetti 
                    width={227} 
                    height={100} 
                    numberOfPieces={confetti ? 500 : 0} 
                    recycle={false}
                    tweenDuration={1000}
                    onConfettiComplete={(c) =>{
                      setConfetti(false);
                      c.reset();
                    }}
                  />
                  <FontAwesomeIcon icon={faExchange} /> Swap and save £{savings}
                </Button>
              }
            </Grid>

            <Grid 
              item 
              xs={12} xl={2} sx={{textAlign: 'center'}}>
              {items.length > 0 &&
              <Button onClick={()=>{
                const empty = emptyCart();

                if(empty){
                  setAltProducts({});
                  toast("Cleared favourites");
                }
              }} variant="contained" color="error" sx={{textTransform: "none"}}>
                <FontAwesomeIcon icon={faTrashCan} /> Clear Favourites
              </Button>
              }
            </Grid>

          </Grid>
          
        </Toolbar>
      </AppBar>

      <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
        <Divider/>
        {sortedItems.map((i, index) => renderListItem(i, index))}
      </List>

    </Dialog>
  );
};

export default ShoppingListDialog;
