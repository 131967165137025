import * as React from "react";
import {ACTION_MORE_RESULTS} from "../actions/actions";

const ShowMoreButton = ({handleMoreResults})=>{
  return (
    <button onClick={e=>handleMoreResults({type: ACTION_MORE_RESULTS})}
        title="Show More Products"
        aria-label="Show More Products"
        className="show-more-button btn btn-primary"
        type="button">Show More Products</button>
  );
};

export default ShowMoreButton;
