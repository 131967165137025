import 'react-app-polyfill/stable';
import "core-js/stable";
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from "@sentry/react";

import App from './components/App';

import './index.css';

Sentry.init({
  dsn: "https://71f125104b6d4571a95526b929a2b5c0@o1168758.ingest.sentry.io/6260915",
  tracesSampleRate: 0,
});

const container = document.getElementById('root');
const root = createRoot(container);
root.render((
  <React.StrictMode>
    <App />
  </React.StrictMode>
));
