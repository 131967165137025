import {calculateCartTotals} from "../general/utils";
import {
  DEV_MODE,
  DIALOG_ID_PRODUCT_DETAIL,
  MAX_SEARCH_LENGTH,
  RESULTS_PER_SEARCH,
  DIALOG_ID_SELECT_CATEGORY,
  DIALOG_ID_SHOPPING_LIST,
  CATEGORY_GENERAL,
  CATEGORY_HALLOWEEN,
  CATEGORY_XMAS,
  CATEGORY_NEW_YEAR,
  CATEGORY_DAIRY_EGGS_CHILLED,
  CATEGORY_BABY_TODDLER,
  CATEGORY_BAKERY,
  CATEGORY_DIETARY_LIFESTYLE,
  CATEGORY_DRINKS,
  CATEGORY_FOOD_CUPBOARD,
  CATEGORY_FRESH_FOOD,
  CATEGORY_FROZEN,
  CATEGORY_FRUIT_VEG,
  CATEGORY_HOMEWARE_OUTDOORS,
  CATEGORY_MEAT_FISH,
  CATEGORY_HOUSEHOLD,
  CATEGORY_TOILETRIES_HEALTH,
  CATEGORY_TOYS,
  CATEGORY_PET,
} from "../general/constants";
import {
  ACTION_EMPTY_CART,
  ACTION_BUSY,
  ACTION_CLEAR_BUSY,
  ACTION_NEW_SEARCH,
  ACTION_SEARCH,
  ACTION_QUERY_SEARCH,
  ACTION_RESULTS,
  ACTION_MORE_RESULTS,
  ACTION_ERROR,
  ACTION_CLOSE_DIALOG,
  ACTION_OPEN_DIALOG,
  ACTION_CATEGORY_TOGGLE,
  ACTION_CATEGORY_RESET,
  ACTION_ADD_TO_LIST,
  ACTION_REMOVE_FROM_LIST,
  ACTION_SORT_BY,
  ACTION_STORE_CHANGE,
  ACTION_ON_OFFER_CHANGE,
  ACTION_OPEN_PRODUCT_DETAILS,
  ACTION_SWAP
} from "../actions/actions";

const STORAGE_KEY = "supermarket101-state";

const DEFAULT_STATE = {
  viewingProduct: null,
  altProducts: null,
  fixedProductResults: null,
  store: "",
  onOffer: false,
  searchText: "",
  myList: {},
  resultsNoFacetSearch: {response: {docs:[], numFound: 0}, facet_counts:{facet_fields:{}}},
  results: {response: {docs:[], numFound: 0}, facet_counts:{facet_fields:{}}},
  maxResults: RESULTS_PER_SEARCH,
  errorMsg: "",
  dialogsVisible: {
    [DIALOG_ID_SELECT_CATEGORY]: false,
    [DIALOG_ID_SHOPPING_LIST]:false,
    [DIALOG_ID_PRODUCT_DETAIL]:false},
  filterByCategory: {
    [CATEGORY_GENERAL]: false,
    [CATEGORY_HALLOWEEN]: false,
    [CATEGORY_XMAS]: false,
    [CATEGORY_NEW_YEAR]: false,
    [CATEGORY_DIETARY_LIFESTYLE]: false,
    [CATEGORY_FRUIT_VEG]: false,
    [CATEGORY_MEAT_FISH]: false,
    [CATEGORY_DAIRY_EGGS_CHILLED]: false,
    [CATEGORY_BAKERY]: false,
    [CATEGORY_FROZEN]: false,
    [CATEGORY_FOOD_CUPBOARD]: false,
    [CATEGORY_DRINKS]: false,
    [CATEGORY_HOUSEHOLD]: false,
    [CATEGORY_TOILETRIES_HEALTH]: false,
    [CATEGORY_TOYS]: false,
    [CATEGORY_HOMEWARE_OUTDOORS]: false,
    [CATEGORY_BABY_TODDLER]: false,
    [CATEGORY_PET]: false,
    [CATEGORY_FRESH_FOOD]: false,
  },
  sortBy: "latest-deals",
  searchToggle: false,
  busy: false,
  viewingSupermarketList: null,
};

const initState = initialState => {
  const clonedState = cloneDeep(initialState);

  if(typeof(Storage) === "undefined"){
    return clonedState;
  }

  const storedState = localStorage.getItem(STORAGE_KEY);

  if(storedState === null){
    storeState(clonedState);
    return clonedState;
  }

  try{
    // Extract some things from local storage.
    const localState = JSON.parse(storedState);

    const mergedState = {
      ...clonedState, // May be DEFAULT_STATE / preloaded by SSR
      ...localState, // Update from local storage
      errorMsg: '',
      busy: false,
      resultsNoFacetSearch: clonedState.resultsNoFacetSearch,
      results: clonedState.results,
      viewingProduct: clonedState.viewingProduct, 
      dialogsVisible: clonedState.dialogsVisible, 
      altProducts: clonedState.altProducts, 
    };

    return mergedState;
  }catch(e){
    if(DEV_MODE){console.error(e);}
    return clonedState;
  }
};

const reducer = (state, action) => {
  const clonedState = cloneDeep(state);

  if(action.type !== ACTION_ERROR){
    clonedState.errorMsg = ``;
  }

  switch (action.type){
    case ACTION_BUSY:
    {
      clonedState.busy = true;
      storeState(clonedState);
      return clonedState;
    }
    case ACTION_CLEAR_BUSY:
    {
      clonedState.busy = false;
      storeState(clonedState);
      return clonedState;
    }
    case ACTION_NEW_SEARCH:
    {
      const newState = {
        ...(cloneDeep(DEFAULT_STATE)),
        searchToggle: !clonedState.searchToggle,
        busy: true,
        myList: clonedState.myList,
        sortBy: clonedState.sortBy,
      };

      storeState(newState);
      return newState;
    }
    case ACTION_QUERY_SEARCH:
    {
      if(action.payload.length > MAX_SEARCH_LENGTH) {
        storeState(clonedState);
        return clonedState;
      }

      clonedState.searchText = action.payload.searchText;
      clonedState.maxResults = RESULTS_PER_SEARCH;
      clonedState.busy = true;

      const newState = {
        ...(cloneDeep(DEFAULT_STATE)),
        searchToggle: !clonedState.searchToggle,
        myList: clonedState.myList,
        sortBy: clonedState.sortBy,
        searchText: clonedState.searchText,
        maxResults: clonedState.maxResults,
        busy: clonedState.busy,
      };

      storeState(newState);
      return newState;
    }    
    case ACTION_EMPTY_CART:
    {
      clonedState.myList = cloneDeep(DEFAULT_STATE.myList);

      storeState(clonedState);
      return clonedState;
    }
    case ACTION_OPEN_PRODUCT_DETAILS:
    {
      const newDialogsVisible = {...clonedState.dialogsVisible};
      newDialogsVisible[DIALOG_ID_PRODUCT_DETAIL] = true;
      clonedState.viewingProduct = cloneDeep( action.payload );
      const newState = {...clonedState, dialogsVisible: newDialogsVisible};
      storeState(newState);
      return newState;
    }
    case ACTION_ON_OFFER_CHANGE:
    {
      clonedState.onOffer = action.payload;
      clonedState.busy = true;
      storeState(clonedState);
      return clonedState;
    }
    case ACTION_STORE_CHANGE:
    {
      const newFilterByCategory = clonedState.filterByCategory;

      for(const categoryName of Object.keys(newFilterByCategory)) {
        newFilterByCategory[categoryName] = false;
      }

      clonedState.busy = true;
      clonedState.store = action.payload;

      storeState(clonedState);
      return clonedState;
    }
    case ACTION_REMOVE_FROM_LIST:
    {
      const productData = action.payload;
      const newMyList = clonedState.myList;

      if(productData.id in newMyList){
        if(newMyList[productData.id].qty === 1){
          delete newMyList[productData.id];
        }else{
          newMyList[productData.id] = {...newMyList[productData.id],  qty: (newMyList[productData.id].qty - 1) };
        }
      }

      storeState(clonedState);
      return clonedState;
    }
    case ACTION_ADD_TO_LIST:
    {
      const productData = action.payload;
      const newMyList = clonedState.myList;

      if(productData.id in newMyList){
        newMyList[productData.id] = {...newMyList[productData.id],  qty: (newMyList[productData.id].qty + 1) };
      }else{
        newMyList[productData.id] = {
          id: productData.id,
          product: productData,
          qty: 1,
          checked: false,
        };
      }

      storeState(clonedState);
      return clonedState;
    }
    case ACTION_SWAP:
    {
      const { altProducts } = action.payload || { altProducts: {} };
      const originalList = clonedState.myList;

      clonedState.myList = {};
      const newMyList = clonedState.myList;

      const ids = Object.keys(altProducts);

      for(const id of ids){
        const arrayOfAltProducts = altProducts[id];
        const qty = originalList[id]?.qty || 1;

        const sortedCheapest = cloneDeep(arrayOfAltProducts).sort( (a, b) => a.product_price - b.product_price );
        const cheapest = sortedCheapest[0];

        const extraQty = originalList[cheapest.id]?.qty || 0;

        newMyList[cheapest.id] = {
          id: cheapest.id,
          product: cheapest,
          qty: qty + extraQty,
          checked: false,
        };
      }

      storeState(clonedState);
      return clonedState;
    }
    case ACTION_CATEGORY_RESET:
    {
      const newFilterByCategory = clonedState.filterByCategory;

      for(const categoryName of Object.keys(newFilterByCategory)) {
        newFilterByCategory[categoryName] = false;
      }

      clonedState.busy = true;
      storeState(clonedState);
      return clonedState;
    }
    case ACTION_CATEGORY_TOGGLE:
    {
      const newFilterByCategory = clonedState.filterByCategory;
      newFilterByCategory[action.payload.name] = action.payload.checked;

      clonedState.busy = true;
      storeState(clonedState);
      return clonedState;
    }
    case ACTION_OPEN_DIALOG:
    {
      const newDialogsVisible = clonedState.dialogsVisible;
      newDialogsVisible[action.payload.dialogId] = true;

      storeState(clonedState);
      return clonedState;
    }
    case ACTION_CLOSE_DIALOG:
    {
      const newDialogsVisible = clonedState.dialogsVisible;
      newDialogsVisible[action.payload] = false;

      if( action.payload === DIALOG_ID_PRODUCT_DETAIL ){
        // Clear the viewing product and alt-products in the state.
        clonedState.viewingProduct = null;
        clonedState.altProducts = null;
      }else if(action.payload === DIALOG_ID_SHOPPING_LIST){
        clonedState.viewingSupermarketList = null;
      }

      storeState(clonedState);
      return clonedState;
    }
    case ACTION_SORT_BY:
    {
      clonedState.sortBy = action.payload;
      clonedState.busy = true;

      storeState(clonedState);
      return clonedState;
    }
    case ACTION_SEARCH:
    {
      if(action.payload.length > MAX_SEARCH_LENGTH) {
        storeState(clonedState);
        return clonedState;
      }

      clonedState.searchText = action.payload.searchText;
      clonedState.maxResults = RESULTS_PER_SEARCH;
      clonedState.busy = true;

      storeState(clonedState);
      return clonedState;
    }    
    case ACTION_RESULTS:
    {
      clonedState.results = action.payload.resultsWithFacetSearch;
      clonedState.resultsNoFacetSearch = action.payload.resultsNoFacetSearch;
      clonedState.busy = false;

      storeState(clonedState);
      return clonedState;
    }
    case ACTION_MORE_RESULTS:
    {
      clonedState.maxResults = clonedState.maxResults + RESULTS_PER_SEARCH;
      clonedState.busy = true;
      
      storeState(clonedState);
      return clonedState;
    }
    case ACTION_ERROR:
    {
      if(DEV_MODE) { console.error(action.payload.errorMsg); }
      return clonedState;
    }
    default:
    {
      throw new Error(`Error, action unknown ${JSON.stringify(action)}`);
    }
  }
};

const storeState = state => {
  if(typeof(Storage) === "undefined"){
    return;
  }

  localStorage.setItem(STORAGE_KEY, JSON.stringify(state) );
};

const cloneDeep = obj => {
  return JSON.parse( JSON.stringify(obj) );
}

const buildListData = (state, dialogId, storeName) => {
  let items = [];

  let total = 0;

  const totals = calculateCartTotals(state.myList);

  if(state.dialogsVisible[dialogId] && storeName){
    total = totals.shopTotals[storeName];

    items = Object.keys(state.myList)
      .filter(p => new RegExp(storeName).test(p))
      .reduce( (acc, val) => {
        acc.push(state.myList[val]);
        return acc;
      }, [] );
  }

  return {items, total};
};

export {STORAGE_KEY, DEFAULT_STATE, initState, reducer, storeState, cloneDeep, buildListData};