import React, { Component } from "react";
import {DEV_MODE} from "../general/constants";

class ErrorBoundary extends Component {

    constructor(props) {
        super(props);

        this.state = {
            hasError: props?.hasError || false,
            errorMsg: props?.errorMsg || ""
        };
    }

    static getDerivedStateFromError(error){
        return {
            hasError: true,
            errorMsg: error
         };
    }

    componentDidCatch(error, errorInfo) {
        if(DEV_MODE){
            console.error(error);
            console.error(errorInfo);
        }
    }

    render() {
        if(this.state.hasError) {

            if(typeof(Storage) !== "undefined"){
                localStorage.clear();
            }

            return (
                <div className="error-ui">
                    <h1>Sorry 🥺 an error has occurred</h1>
                    <a href="/">
                        <button type="button" className="btn btn-success">Try Again</button>
                    </a>
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
