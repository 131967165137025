import {
  SUPERMARKET_NAME_ALDI,
  SUPERMARKET_NAME_ASDA,
  SUPERMARKET_NAME_MORRISONS, 
  SUPERMARKET_NAME_SAINSBURYS, 
  SUPERMARKET_NAME_TESCO, 
  SUPERMARKET_NAME_WAITROSE,
  STORE_NAMES,
  MY_SUPERMARKET_HOST
} from "./constants";

function generateStructuredData(productData){
  const d = new Date();
  d.setFullYear(d.getFullYear()+1);

  const productLink = buildProductLink(productData);

  const description = productData.hasOwnProperty("product_description") && productData.product_description.length > 0 ? productData.product_description : productData.product_name;

  return JSON.stringify(
    {
      "@context": "https://schema.org/",
      "@type": "Product",
      "mpn": productData.id,
      "name": productData.product_name,
      "image": [
        `${productData.product_image}`
       ],
      "description": description,
      "sku": `${productData.category_name}||${productData.product_name}`,
      "brand": {
        "@type": "Brand",
        "name": STORE_NAMES[productData.supermarket_name]
      },
      "offers": {
        "url": `${MY_SUPERMARKET_HOST}${productLink}`,
        "priceValidUntil": d.toISOString().split("T").shift(),
        "@type": "Offer",
        "availability": "https://schema.org/InStock",
        "price": productData.product_price,
        "priceCurrency": "GBP"
      },
      "url": `${MY_SUPERMARKET_HOST}${productLink}`
    });
}

function ucFirst(s) {
  if(s === undefined || s === null){
    return s;
  }

  return `${s.charAt(0).toUpperCase()}${s.slice(1).toLowerCase()}`;
}

function ucFirstWords(s){
  if(s === undefined || s === null){
    return s;
  }

  return s.replace(/_/g, " ").split(/\s+/).map(b=>ucFirst(b)).join(" ");
}

function buildProductLink(productData){
  return `/product/${encodeURIComponent(productData.supermarket_name)}||${encodeURIComponent(productData.category)}||${encodeURIComponent(productData.product_name)}/${encodeURIComponent(productData.id)}`;
}

function calculateCartTotals(myList){

  const allListItemsBySupermarket = {};
  allListItemsBySupermarket[SUPERMARKET_NAME_ALDI] = [];
  allListItemsBySupermarket[SUPERMARKET_NAME_ASDA] = [];
  allListItemsBySupermarket[SUPERMARKET_NAME_MORRISONS] = [];
  allListItemsBySupermarket[SUPERMARKET_NAME_SAINSBURYS] = [];
  allListItemsBySupermarket[SUPERMARKET_NAME_TESCO] = [];
  allListItemsBySupermarket[SUPERMARKET_NAME_WAITROSE] = [];

  const shopTotals = [];
  shopTotals[SUPERMARKET_NAME_ALDI] = 0;
  shopTotals[SUPERMARKET_NAME_ASDA] = 0;
  shopTotals[SUPERMARKET_NAME_MORRISONS] = 0;
  shopTotals[SUPERMARKET_NAME_SAINSBURYS] = 0;
  shopTotals[SUPERMARKET_NAME_TESCO] = 0;
  shopTotals[SUPERMARKET_NAME_WAITROSE] = 0;
  let totalPrice = 0;

  for(const listItem of Object.values(myList)) {
    shopTotals[listItem.product.supermarket_name] += (listItem.qty * listItem.product.product_price);
    totalPrice += (listItem.qty * listItem.product.product_price);
  }

  return {
    allListItemsBySupermarket: allListItemsBySupermarket,
    shopTotals: shopTotals,
    totalPrice: totalPrice
  };
}

export {buildProductLink,generateStructuredData,ucFirst,ucFirstWords,calculateCartTotals};
