import * as React from 'react';
import DEFAULT_IMAGE from "../images/logo-192.png";
import AldiImage from '../images/aldi.svg';
import AsdaImage from '../images/asda.svg';
import MorrisonsImage from '../images/morrisons.svg';
import SainsburysImage from '../images/sainsburys.svg';
import TescoImage from '../images/tesco.svg';
import WaitroseImage from '../images/waitrose.svg';

let DEV_MODE = false;
let DEV_HYDRATE = true;

if( typeof(Storage) !== "undefined" ){

  if(localStorage.getItem("DEV_MODE") !== null){
    DEV_MODE = (localStorage.getItem("DEV_MODE")  === "true");
  }

  if(localStorage.getItem("DEV_HYDRATE") !== null){
    DEV_HYDRATE = (localStorage.getItem("DEV_HYDRATE")  === "true");
  }

}else if( typeof(process) !== "undefined" && process.hasOwnProperty("env") && process.env.hasOwnProperty("NODE_ENV") ){
  DEV_MODE = (process.env.NODE_ENV === "development");

}else if( typeof(process) !== "undefined" && process.hasOwnProperty("env") && process.env.hasOwnProperty("DEV_HYDRATE") ){
  DEV_HYDRATE = (process.env.DEV_HYDRATE === "true");
}

let MY_SUPERMARKET_HOST = "https://supermarket101.uk";

if( DEV_MODE ){
  MY_SUPERMARKET_HOST = "http://localhost:18181";
}

const MAX_SEARCH_RESULTS = 100;
const RESULTS_PER_SEARCH = 20;
const MAX_SEARCH_LENGTH = 50;

const BLOG_HOST = `https://blog.supermarket101.uk`;

const TITLE = `Supermarket101`;
const DIALOG_ID_SHOPPING_LIST = "shopping-list";
const DIALOG_ID_SELECT_CATEGORY = "select-category";
const DIALOG_ID_PRODUCT_DETAIL = "product-detail";
const CATEGORY_GENERAL = "GENERAL";
const CATEGORY_HALLOWEEN = "HALLOWEEN";
const CATEGORY_XMAS = "XMAS";
const CATEGORY_NEW_YEAR = "NEW_YEAR";
const CATEGORY_DIETARY_LIFESTYLE = "DIETARY_LIFESTYLE";
const CATEGORY_FRUIT_VEG = "FRUIT_VEG";
const CATEGORY_MEAT_FISH = "MEAT_FISH";
const CATEGORY_DAIRY_EGGS_CHILLED = "DAIRY_EGGS_CHILLED";
const CATEGORY_BAKERY = "BAKERY";
const CATEGORY_FROZEN = "FROZEN";
const CATEGORY_FOOD_CUPBOARD = "FOOD_CUPBOARD";
const CATEGORY_DRINKS = "DRINKS";
const CATEGORY_HOUSEHOLD = "HOUSEHOLD";
const CATEGORY_TOILETRIES_HEALTH = "TOILETRIES_HEALTH";
const CATEGORY_TOYS = "TOYS";
const CATEGORY_HOMEWARE_OUTDOORS = "HOMEWARE_OUTDOORS";
const CATEGORY_BABY_TODDLER = "BABY_TODDLER";
const CATEGORY_PET = "PET";
const CATEGORY_FRESH_FOOD = "FRESH_FOOD";

const SUPERMARKET_NAME_ALDI = "aldi";
const SUPERMARKET_NAME_ASDA = "asda";
const SUPERMARKET_NAME_MORRISONS = "morrisons";
const SUPERMARKET_NAME_SAINSBURYS = "sainsburys";
const SUPERMARKET_NAME_TESCO = "tesco";
const SUPERMARKET_NAME_WAITROSE = "waitrose";

const STORE_NAMES = {
  [SUPERMARKET_NAME_ALDI]: "Aldi",
  [SUPERMARKET_NAME_ASDA]: "Asda",
  [SUPERMARKET_NAME_MORRISONS]: "Morrisons",
  [SUPERMARKET_NAME_SAINSBURYS]: "Sainsbury's",
  [SUPERMARKET_NAME_TESCO]: "Tesco",
  [SUPERMARKET_NAME_WAITROSE]: "Waitrose"
};

const SHOP_LOGOS = new Map([
  [SUPERMARKET_NAME_ALDI, <img src={AldiImage} alt={STORE_NAMES[SUPERMARKET_NAME_ALDI]} /> ],
  [SUPERMARKET_NAME_ASDA, <img src={AsdaImage} alt={STORE_NAMES[SUPERMARKET_NAME_ASDA]} /> ],
  [SUPERMARKET_NAME_MORRISONS, <img src={MorrisonsImage} alt={STORE_NAMES[SUPERMARKET_NAME_MORRISONS]} /> ],
  [SUPERMARKET_NAME_SAINSBURYS, <img src={SainsburysImage} alt={STORE_NAMES[SUPERMARKET_NAME_SAINSBURYS]} /> ],
  [SUPERMARKET_NAME_TESCO, <img src={TescoImage} alt={STORE_NAMES[SUPERMARKET_NAME_TESCO]} /> ],
  [SUPERMARKET_NAME_WAITROSE, <img src={WaitroseImage} alt={STORE_NAMES[SUPERMARKET_NAME_WAITROSE]} /> ]
]);

const EMPTY_PRODUCT = {
  empty: true,
  id: '',
  supermarket_name: '',
  product_name: 'No Product Found',
  product_price: 0,
  product_image: DEFAULT_IMAGE,
  product_image_thumb: DEFAULT_IMAGE,
  category_name: CATEGORY_GENERAL,
  category: CATEGORY_GENERAL,
  product_description: '',
  qty: 1,
  term: '',
};

export {
  EMPTY_PRODUCT,
  SUPERMARKET_NAME_ALDI,
  SUPERMARKET_NAME_ASDA,
  SUPERMARKET_NAME_MORRISONS, 
  SUPERMARKET_NAME_SAINSBURYS, 
  SUPERMARKET_NAME_TESCO, 
  SUPERMARKET_NAME_WAITROSE,
  STORE_NAMES,
  SHOP_LOGOS,
  DEFAULT_IMAGE,
  MAX_SEARCH_RESULTS,
  MY_SUPERMARKET_HOST,
  DEV_MODE,
  DEV_HYDRATE,
  RESULTS_PER_SEARCH,
  MAX_SEARCH_LENGTH,
  BLOG_HOST,
  TITLE,
  DIALOG_ID_SHOPPING_LIST,
  DIALOG_ID_SELECT_CATEGORY,
  DIALOG_ID_PRODUCT_DETAIL,
  CATEGORY_GENERAL,
  CATEGORY_HALLOWEEN,
  CATEGORY_XMAS,
  CATEGORY_NEW_YEAR,
  CATEGORY_DIETARY_LIFESTYLE,
  CATEGORY_FRUIT_VEG,
  CATEGORY_MEAT_FISH,
  CATEGORY_DAIRY_EGGS_CHILLED,
  CATEGORY_BAKERY,
  CATEGORY_FROZEN,
  CATEGORY_FOOD_CUPBOARD,
  CATEGORY_DRINKS,
  CATEGORY_HOUSEHOLD,
  CATEGORY_TOILETRIES_HEALTH,
  CATEGORY_TOYS,
  CATEGORY_HOMEWARE_OUTDOORS,
  CATEGORY_BABY_TODDLER,
  CATEGORY_PET,
  CATEGORY_FRESH_FOOD,
};
