const ACTION_SEARCH = "search";
const ACTION_QUERY_SEARCH = "query-search";
const ACTION_RESULTS = "results";
const ACTION_MORE_RESULTS = "more-results";
const ACTION_ERROR = "error";
const ACTION_OPEN_DIALOG = "open-dialog";
const ACTION_CLOSE_DIALOG = "close-dialog";
const ACTION_CATEGORY_TOGGLE = "toggle-category";
const ACTION_CATEGORY_RESET = "reset-category";
const ACTION_ADD_TO_LIST = "add-to-list";
const ACTION_REMOVE_FROM_LIST = "remove-from-list";
const ACTION_SORT_BY = "sort-by";
const ACTION_STORE_CHANGE = "store-change";
const ACTION_ON_OFFER_CHANGE = "on-offer";
const ACTION_OPEN_PRODUCT_DETAILS = "view-product-details";
const ACTION_NEW_SEARCH = "new-search";
const ACTION_CLEAR_BUSY = "clear-busy";
const ACTION_BUSY = "busy";
const ACTION_EMPTY_CART = 'empty-cart';
const ACTION_SWAP = 'swap';

export {
  ACTION_QUERY_SEARCH,
  ACTION_EMPTY_CART,
  ACTION_BUSY,
  ACTION_CLEAR_BUSY,
  ACTION_NEW_SEARCH,
  ACTION_ON_OFFER_CHANGE,
  ACTION_STORE_CHANGE,
  ACTION_SEARCH,
  ACTION_RESULTS,
  ACTION_MORE_RESULTS,
  ACTION_ERROR,
  ACTION_CLOSE_DIALOG,
  ACTION_OPEN_DIALOG,
  ACTION_CATEGORY_TOGGLE,
  ACTION_CATEGORY_RESET,
  ACTION_ADD_TO_LIST,
  ACTION_REMOVE_FROM_LIST,
  ACTION_SORT_BY,
  ACTION_OPEN_PRODUCT_DETAILS,
  ACTION_SWAP
};
